import React from 'react';
import './style.scss';
import Navbar from '../../Navbar';
import CTAButtons from '../../CTAButtons';

const Header = () => {
    return (
        <div className='pluseSurveyWrap'>
            <div className='triviaContainer'>
                <Navbar />

                <div className='pluseSurveyContent'>
                    <div className='pluseSurveyContentLeft'>
                        <h1>
                            Adios Surveys{' '}
                            <span role={'img'} aria-label={'bye'}>
                                👋
                            </span>
                            <br />
                            Howdy Pulse
                            <span role={'img'} aria-label={'shake hands'}>
                                🤝
                            </span>
                        </h1>
                        <p>
                            Send a quick, contextual Pulse. Get instant feedback from your team on Slack and Microsoft
                            Teams.{' '}
                        </p>
                        <CTAButtons />
                    </div>
                    <div className='pluseSurveyContentRight'>
                        <img
                            src={'https://sw-assets.gumlet.io/engagewith/pulse-vs-survey/header-illustration.svg'}
                            data-src={'https://sw-assets.gumlet.io/engagewith/pulse-vs-survey/header-illustration.svg'}
                            alt=''
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
