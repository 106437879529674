import React from 'react';
import './style.scss';
import CTAButtons from '../../CTAButtons';

const GetStarted = () => {
    return (
        <div className='ewGetStartedWrap'>
            <div className='triviaContainer'>
                <div className='getStartedHeader'>
                    <h2>Three steps to get started</h2>
                </div>

                <div className='ewGetStarted'>
                    <div className='ewGetStartedLeft'>
                        <img
                            src={'https://sw-assets.gumlet.io/engagewith/pulse-vs-survey/illustration.gif'}
                            data-src={'https://sw-assets.gumlet.io/engagewith/pulse-vs-survey/illustration.gif'}
                            alt=''
                        />
                    </div>
                    <div className='ewGetStartedRight'>
                        <div className='ewGetStartedStep'>
                            <img
                                src={'https://sw-assets.gumlet.io/engagewith/pulse-vs-survey/add-engage-with.svg'}
                                data-src={'https://sw-assets.gumlet.io/engagewith/pulse-vs-survey/add-engage-with.svg'}
                                alt=''
                            />
                            <h3>Add EngageWith</h3>
                            <p>
                                Search for EngageWith in your respective app store. Or click on ‘Add EngageWith’ in the
                                top-right corner of this page.{' '}
                            </p>
                        </div>
                        <div className='ewGetStartedStep'>
                            <img
                                src={'https://sw-assets.gumlet.io/engagewith/pulse-vs-survey/send.svg'}
                                data-src={'https://sw-assets.gumlet.io/engagewith/pulse-vs-survey/send.svg'}
                                alt=''
                            />
                            <h3>Send a Pulse</h3>
                            <p>
                                Create a Pulse or choose from our 45+ pre-built templates. Send them personally or post
                                them on a channel for your people to involve, engage and participate.{' '}
                            </p>
                        </div>
                        <div className='ewGetStartedStep'>
                            <img
                                src={'https://sw-assets.gumlet.io/engagewith/pulse-vs-survey/get.svg'}
                                data-src={'https://sw-assets.gumlet.io/engagewith/pulse-vs-survey/get.svg'}
                                alt=''
                            />
                            <h3>Get to know your team</h3>
                            <p>
                                Collect real-time responses, analyze and get deeper insights about your team instantly.{' '}
                            </p>
                        </div>
                    </div>
                </div>

                <CTAButtons />
            </div>
        </div>
    );
};

export default GetStarted;
