import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../../../components/engagewith/PulseVsSurvey/Header';
import Footer from '../../../components/engagewith/Footer';
import './styles.scss';
import WhyReplace from '../../../components/engagewith/PulseVsSurvey/WhyReplace';
import GetStarted from '../../../components/engagewith/PulseVsSurvey/GetStarted';
import FastFunSimple from '../../../components/engagewith/PulseVsSurvey/FastFunSimple';
import ScrapSurvey from '../../../components/engagewith/PulseVsSurvey/ScrapSurvey';

const Lander = () => {
    return (
        <div>
            <Helmet>
                <title>Ditch surveys. Get instant feedback with Pulses | EngageWith</title>
                <meta property='og:title' content='Ditch surveys. Get instant feedback with Pulses | EngageWith' />
                <meta property='og:site_name' content='EngageWith' />
                <meta property='og:url' content='https://www.springworks.in/engagewith/pulse-vs-survey/' />
                <meta
                    property='og:description'
                    content='Capture contextual feedback from your team in seconds inside Slack & Microsoft Teams. Use our scheduler to regularly send engaging Pulses to your team.'
                />
                <meta
                    name='description'
                    content='Capture contextual feedback from your team in seconds inside Slack & Microsoft Teams. Use our scheduler to regularly send engaging Pulses to your team.'
                />
                <meta property='og:type' content='website' />
                <meta
                    property='og:image'
                    content='https://assets-springengage.s3.amazonaws.com/web/engage_header_logo.png'
                />
                <link rel='canonical' href='https://www.springworks.in/engagewith/pulse-vs-survey/' />
                <meta name='twitter:card' content='summary' />
                <meta name='twitter:site' content='@springroleinc' />
                <meta name='twitter:title' content='Ditch surveys. Get instant feedback with Pulses | EngageWith' />
                <meta
                    name='twitter:description'
                    content='Capture contextual feedback from your team in seconds inside Slack & Microsoft Teams. Use our scheduler to regularly send engaging Pulses to your team.'
                />
                <meta
                    name='twitter:image'
                    content='https://assets-springengage.s3.amazonaws.com/web/engage_header_logo.png'
                />
                <meta property='og:image:width' content='1200' />
                <meta property='og:image:height' content='600' />
                <script type='application/ld+json'>
                    {`{
                        "@context": "https://schema.org",
                        "@type": "Organization",
                        "name": "EngageWith",
                        "url": "https://www.springworks.in/engagewith/",
                        "logo": "https://d1xyh4hv0scrr0.cloudfront.net/web/engage_logo.jpg",
                        "sameAs": [
                          "https://www.facebook.com/springrole",
                          "https://twitter.com/springroleinc/",
                          "https://www.linkedin.com/organization-guest/company/springrole/",
                          "https://www.youtube.com/channel/UC7jYeZWJHhsV_2266gVMsZw"
                        ]
                    }`}
                </script>
            </Helmet>
            <Header />
            <WhyReplace />
            <GetStarted />
            <FastFunSimple />
            <ScrapSurvey />
            <Footer />
        </div>
    );
};

export default Lander;
