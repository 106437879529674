import React from 'react';
import './style.scss';
import CTAButtons from '../../CTAButtons';

const ScrapSurvey = () => {
    return (
        <div className='scrapSurveyWrap'>
            <div className='triviaContainer'>
                <div className='scrapSurvey'>
                    <div className='scrapSurveyContent'>
                        <h3>
                            Scrap surveys. Send
                            <br />a Pulse
                        </h3>
                        <CTAButtons />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ScrapSurvey;
