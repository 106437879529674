import React from 'react';
import './style.scss';

const WhyReplace = () => {
    return (
        <div className='whyReplaceWrap'>
            <div className='triviaContainer'>
                <div className='whyReplaceInner'>
                    <div className='whyReplaceLeft'>
                        <h2>Why it’s time to replace boring surveys with an engaging Pulse</h2>
                    </div>
                    <div className='whyReplaceRight'>
                        <div className='whyReplace'>
                            <h5>10X</h5>
                            <p>Faster than traditional surveys</p>
                        </div>
                        <div className='whyReplace'>
                            <h5>45+</h5>
                            <p>Handy templates to choose</p>
                        </div>
                        <div className='whyReplace'>
                            <h5>250%</h5>
                            <p>Increase in response rates</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WhyReplace;
