import React from 'react';
import './style.scss';

const FastFunSimple = () => {
    return (
        <div className='fastFunSimpleWrap'>
            <div className='triviaContainer'>
                <div className='fastFunSimpleHeader'>
                    <h3>Fast, fun, and super-simple</h3>
                </div>

                <div className='fastFunSimple'>
                    <div className='fastFunSimpleCard'>
                        <img
                            src={'https://sw-assets.gumlet.io/engagewith/pulse-vs-survey/pre-built.svg'}
                            data-src={'https://sw-assets.gumlet.io/engagewith/pulse-vs-survey/pre-built.svg'}
                            alt=''
                        />
                        <h4>Pre-built templates</h4>
                        <p>
                            Employee opinions? Feedback? With 45+ templates, EngageWith makes it dead-simple to know
                            what your team thinks.
                        </p>
                    </div>
                    <div className='fastFunSimpleCard'>
                        <img
                            src={'https://sw-assets.gumlet.io/engagewith/pulse-vs-survey/schedule.svg'}
                            data-src={'https://sw-assets.gumlet.io/engagewith/pulse-vs-survey/schedule.svg'}
                            alt=''
                        />
                        <h4>Schedule it. Forget it. </h4>
                        <p>
                            Want to run a weekly Pulse? Auto-schedule them well in advance, sit back and relax! Wait for
                            the responses to flow in.{' '}
                        </p>
                    </div>
                    <div className='fastFunSimpleCard'>
                        <img
                            src={'https://sw-assets.gumlet.io/engagewith/pulse-vs-survey/incognito.svg'}
                            data-src={'https://sw-assets.gumlet.io/engagewith/pulse-vs-survey/incognito.svg'}
                            alt=''
                        />
                        <h4>Incognito friendly</h4>
                        <p>Send and receive responses anonymously. Inspire trust and transparency. </p>
                    </div>
                </div>

                <div className='fastFunSimple'>
                    <div className='fastFunSimpleCard'>
                        <img
                            src={'https://sw-assets.gumlet.io/engagewith/pulse-vs-survey/remote.svg'}
                            data-src={'https://sw-assets.gumlet.io/engagewith/pulse-vs-survey/remote.svg'}
                            alt=''
                        />
                        <h4>Remote ready</h4>
                        <p>
                            Get to know your team even while working remotely. Multiple choice questions? Rating scale?
                            Text responses? You name it, we’ve got it.{' '}
                        </p>
                    </div>
                    <div className='fastFunSimpleCard'>
                        <img
                            src={'https://sw-assets.gumlet.io/engagewith/pulse-vs-survey/receive.svg'}
                            data-src={'https://sw-assets.gumlet.io/engagewith/pulse-vs-survey/receive.svg'}
                            alt=''
                        />
                        <h4>Receive real-time response every time </h4>
                        <p>
                            EngageWith Pulse captures responses as they come in. Visualize your data effortlessly and
                            make better decisions faster.{' '}
                        </p>
                    </div>
                    <div className='fastFunSimpleCard'>
                        <img
                            src={'https://sw-assets.gumlet.io/engagewith/pulse-vs-survey/works.svg'}
                            data-src={'https://sw-assets.gumlet.io/engagewith/pulse-vs-survey/works.svg'}
                            alt=''
                        />
                        <h4>Works where work happens</h4>
                        <p>
                            Everything happens right inside Slack and Microsoft Teams. So anyone can respond and get
                            back to what they were doing in no time.{' '}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FastFunSimple;
